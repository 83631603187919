import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.scss";
//Contact Us email changed 02.10.2022
const Footer = () => {
  return (
    <div className="row">
      <div className="col-lg-12 footer-wrapper">
        <div className="row">
          <div className="col-xl-3  col-6 footer-boxes">
            <p className="title">Connect</p>
            <ul>
              <li>
                <NavLink to="/blog">Newsletter</NavLink>
              </li>
              <li>
                <a href="mailto:customercare@frontiercoop.com">Contact Us</a>
              </li>
            </ul>
          </div>
          <div className="col-xl-3  col-6 footer-boxes">
            <p className="title">Learn</p>
            <ul>
              <li>
                <NavLink to="/essential-oil-basics">
                  Essential Oil Basics
                </NavLink>
              </li>
              <li>
                <NavLink to="/games">Games</NavLink>
              </li>
            </ul>
          </div>
          <div className="col-xl-3  col-6 footer-boxes">
            <p className="title">Review</p>
            <ul>
              <li>
                <NavLink to="/resources">Resources</NavLink>
              </li>
              <li>
                <NavLink to="/oildirectory">Directory</NavLink>
              </li>
            </ul>
          </div>
          <div className="col-xl-3  col-6 footer-boxes">
            <p className="title">Other</p>
            <ul>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/privacy">Privacy Policy</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
