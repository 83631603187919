import React, { lazy, Suspense, useState, useEffect, useCallback } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import axios from "./axios";
import AuthContext from "./Shared/Auth/auth-context";
import "./App.scss";

import Footer from "./Shared/Components/Footer/Footer";

const Login = lazy(() => import("./Pages/Login/Login"));
const Pages = lazy(() => import("./Pages"));
const Register = lazy(() => import("./Pages/Register/Register"));
const Forgot = lazy(() => import("./Pages/Forgot/Forgot"));
const Privacy = lazy(() =>
  import("./Shared/Components/PrivacyPoliciy/Privacy")
);
const ResetPassword = lazy(() => import("./Pages/ResetPassword/ResetPassword"));
const VerifyEmail = lazy(() => import("./Pages/VerifyEmail/VerifyEmail"));
const OilSingle = lazy(() => import("./Pages/Directory/SingleOil"));
const Directory = lazy(() => import("./Pages/Directory/Directory"));
const SingleArticle = lazy(() => import("./Pages/Newsletter/Article"));
const ArticlCatagory = lazy(() => import("./Pages/Newsletter/Catagory"));
const Blog = lazy(() => import("./Pages/Newsletter/Blog"));

function App() {
  const [isLoggedIn, setIsLoggedin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [score, setScore] = useState();
  const [badge, setBadge] = useState();
  const history = useHistory();
  const location = useLocation();

  const checkAuthentication = useCallback(async () => {
    try {
      await axios({
        method: "GET",
        url: "api/user",
        withCredentials: true,
      });
      setIsLoggedin(true);

      localStorage.setItem("loggedin", true);
      localStorage.setItem("auracacia", "brella_productions_cookie");
    } catch (err) {
      localStorage.removeItem("loggedin");

      if (
        location.pathname !== "/register" &&
        location.pathname !== "/forgot" &&
        location.pathname !== "/blog" &&
        location.pathname !== "/oildirectory" &&
        location.pathname !== "/privacy" &&
        !location.pathname.startsWith("/password/reset/") &&
        !location.pathname.startsWith("/article/") &&
        !location.pathname.startsWith("/articles/catagory/") &&
        !location.pathname.startsWith("/content/") &&
        !location.pathname.startsWith("/email/verify")
      ) {
        if (location.search) {
          history.push("/login" + location.search);
        } else {
          history.push("/login");
        }
      }
      setIsLoggedin(false);
    } finally {
      setLoading(false);
    }
  }, [history, location.pathname, location.search]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  const loginHandler = () => {
    setIsLoggedin(true);
    localStorage.setItem("loggedin", true);
    history.push("/");
  };

  const logoutHandler = async () => {
    try {
      await axios({
        method: "POST",
        url: "api/logout",
      });
      setIsLoggedin(false);
      localStorage.removeItem("loggedin");
      history.push("/login");
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return "...loading";
  }

  let routes;
  //alert("test");

  if (isLoggedIn) {
    routes = (
      <Suspense fallback="Loading...">
        <Switch>
          <Route
            path="/"
            render={(props) => (
              <Pages {...props} logoutHandler={logoutHandler} />
            )}
          />
        </Switch>
      </Suspense>
    );
  } else {
    routes = (
      <Suspense fallback="Loading...">
        <Switch>
          <Route path="/directory" exact component={Directory} />
          <Route
            path="/login"
            exact
            render={() => <Login loginHandler={loginHandler} />}
          />
          <Route path="/register" exact component={Register} />
          <Route path="/forgot" exact component={Forgot} />
          <Route
            path="/articles/catagory/:id"
            exact
            component={ArticlCatagory}
          />
          <Route path="/article/:id" exact component={SingleArticle} />
          <Route path="/oildirectory" exact component={Directory} />
          <Route path="/content/:alias" exact component={OilSingle} />
          <Route
            path="/password/reset/:token"
            exact
            component={ResetPassword}
          />
          <Route path="/privacy" exact component={Privacy} />
          <Route path="/email/:token" exact component={VerifyEmail} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/article/:id" exact component={SingleArticle} />
          <Route
            path="/articles/catagory/:id"
            exact
            component={ArticlCatagory}
          />
          <Route
            path="/"
            render={(props) => (
              <Pages {...props} logoutHandler={logoutHandler} />
            )}
          />
        </Switch>
        <Footer />
      </Suspense>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        score: score,
        scoreHandler: (val) => setScore(val),
        badge: badge,
        badgeHandler: (val) => setBadge(val),
      }}
    >
      <div className="container-fluid">{routes}</div>
    </AuthContext.Provider>
  );
}
export default App;
