import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

export default axiosInstance;

//process.env.REACT_APP_API_BASE_URL,
//"http://localhost:8000",
